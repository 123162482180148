.post {
    text-align: center;
    width: 282px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 1.5rem;
    transition: transform .2s;
}

.post:hover {
    transform: translate(0, -1rem);
}

.capa {
    width: 100%;
}

.titulo {
    font-family: var(--fonte-secundaria);
    font-size: 1.25rem;
    color: var(--azul-escuro);
    line-height: 1.75rem;
    margin: 1.5rem 0 1.75rem;
    min-height: 3.5rem;
}

@media (max-width: 1100px) {
    .post {
        width: 336px;
    }
    .titulo {
        font-weight: 600;
    }
}