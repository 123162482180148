.fotoSobreMim {
    margin-bottom: .5rem;
    margin-left: 1.5rem;
    width: 40vw;
    float: right;
}

.subtitulo {
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.paragrafo {
    text-align: justify;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
}

@media (max-width: 1100px) {
    .fotoSobreMim {
        float: none;
        display: block;
        margin: 0 auto 2rem;
        width: 70vw;
    }

    .paragrafo {
        margin-bottom: 2rem;
    }
}

@media (max-width: 744px) {
    .fotoSobreMim {
        width: 100%;
    }

    .subtitulo {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }

    .paragrafo {
        font-size: 1rem;
        line-height: 1.75rem;
    }
}