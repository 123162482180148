.banner {
    padding: 3rem 7.5rem 6.25rem;
    background-color: var(--azul-escuro);
    color: var(--branco);
    display: grid;
    grid-template-columns: 1fr 1fr;
}
 
.titulo {
    font-family: var(--fonte-secundaria);
    font-size: 3rem;
    margin-top: 4.875rem;
    margin-bottom: 2rem;
}
 
.paragrafo {
    font-family: var(--fonte-secundaria);
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
}
 
.imagens {
    position: relative;
    height: 406px;
}
 
.imagens > .minhaFoto {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18vw;
    border-radius: 50%;
}
 
.imagens > .circuloColorido {
    position: absolute;
    right: 7vw;
    bottom: 2.2vw;
    width: 25vw;
}
 
@media (max-width: 1100px) {    
    .banner {
        padding: 3.5rem 1.5rem;
    }
 
    .titulo {
        margin-top: 0;
        margin-bottom: 1.5rem;
    }
 
    .paragrafo {
        margin-bottom: 1.5rem;
    }
 
    .imagens {
        height: 338px;
    }
   
    .imagens > .minhaFoto {
        width: 25vw;
    }
   
    .imagens > .circuloColorido {
        right: 10vw;
        width: 35vw;
    }
}
 
@media (max-width: 744px) {
    .banner {
        padding: 2rem 1rem;
        display: block;
    }
 
    .titulo {
        margin-bottom: 2rem;
    }
 
    .paragrafo {
        margin-bottom: 2rem;
    }
 
    .imagens {
        height: 307px;
    }
 
    .imagens > .minhaFoto, .imagens > .circuloColorido {
        left: 0;
        right: 0;
        margin: 0 auto;
    }
   
    .imagens > .minhaFoto {
        width: 170px;
        transform: translate(5rem, 0px);
    }
   
    .imagens > .circuloColorido {
        width: 240px;
        transform: translate(-1.5rem, 0px);
    }
}