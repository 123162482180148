* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --azul-escuro: #0f0228;
  --azul-medio: #1875E8;
  --cor-fonte-principal: #041833;
  --cor-fonte-post: #444444;
  --branco: #FFF;
  --fonte-principal: 'Raleway', sans-serif;
  --fonte-secundaria: 'Playfair Display', sans-serif;

  font-family: var(--fonte-principal);
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
}

a, button, input, textarea {
  font: inherit;
  color: inherit;
}

button {
  background-color: transparent;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

h1, h2, h3 {
  font-weight: 700;
  line-height: 5rem;
}