.post-markdown-container h3,
.post-markdown-container h4,
.post-markdown-container h5,
.post-markdown-container h6 {
    font-family: var(--fonte-secundaria);
}

.post-markdown-container h3 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.post-markdown-container h4 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.post-markdown-container p {
    text-align: justify;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 1.5rem;
}

.post-markdown-container a {
    color: #0493d4;
    text-decoration: underline;
}

.post-markdown-container img {
    display: block;
    margin: 0 auto;
}

.post-markdown-container ul {
    font-size: 1.5rem;
}

.post-markdown-container ul > li {
    margin-bottom: 1rem;
    list-style: disc;
}

.post-markdown-container pre,
.post-markdown-container blockquote {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #eaeaea;
    border-radius: 5px;
}

.post-markdown-container pre {
    overflow-x: auto;
}

@media (max-width: 1100px) {
    .post-markdown-container p {
        margin-bottom: 2rem;
    }
}

@media (max-width: 744px) {
    .post-markdown-container h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }

    .post-markdown-container p {
        font-size: 1rem;
        line-height: 1.75rem;
    }
}