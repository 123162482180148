.postModeloContainer {
    color: var(--cor-fonte-post);
    position: relative;
}

.fotoCapa,
.titulo {
    height: 204px;
    line-height: 2rem;
}

.fotoCapa {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
    position: absolute;
    top: 0;
    z-index: -1;
}

.titulo {
    font-family: var(--fonte-secundaria);
    padding: 0 7.5rem;
    font-size: 4rem;
    display: flex;
    align-items: center;
}

.postConteudoContainer {
    padding: 2.5rem 7.5rem 4.5rem;
}

@media (max-width: 744px) {
    .postConteudoContainer {
        padding: 2rem 1.5rem 1.5rem;
    }
}

@media (max-width: 744px) {

    .fotoCapa,
    .titulo {
        height: 104px;
    }

    .titulo {
        padding: 0 1rem;
        font-size: 2rem;
    }

    .postConteudoContainer {
        padding: 2rem 1rem 1.5rem;
    }
}